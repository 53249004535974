// Example
//
// body {
//   background-color: color('primary');
// }

// *****************************
// Grid
// *****************************
$enable-grid-classes:       true !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

$prefix-max-widths: (
  xs-: "(max-width: 575px)",
  sm-: "(max-width: 767px)",
  md-: "(max-width: 991px)",
  lg-: "(max-width: 1199px)",
  xl-: "(min-width: 1200px)",
);

@mixin set-breakpoint-prefixes {
  @content('');
  @each $prefix, $media in $prefix-max-widths {
    @media screen and (#{$media}) {
      @content('#{$prefix}-');
    }
  }
}