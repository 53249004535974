.modal-holder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 80px;
    visibility: hidden;
    z-index: 100;
    &.toggled {
        visibility: visible;
    }
    .modal-bg {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5); 
        cursor: pointer;
    }
    .modal-inner {
        width: 702px;
        background: #FFFFFF;
        border-radius: 8px;
        padding: 49px 47px;
        margin: 0 auto;
        position: relative;
        max-height: 90vh;
        .modal-close {
            background: #FBEAE7;
            border-radius: 4px;
            color: #B92D0C;
            font-size: 28px;
            height: 32px;
            width: 32px;
            line-height: 32px;
            position: absolute;
            left: calc(100% + 16px);
            top: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .modal-scrollholder {
            max-height: calc(90vh - 98px);
            overflow: auto;
            padding-left: 47px;
            padding-right: 47px;
        }
    }

    &.confirmation {
        position: absolute;
        bottom: unset;
        left: 0;
        top: 0;
        right: unset;
        padding-top: 0;

        .modal-bg {
            background: rgba(0, 0, 0, .2);
        }

        .modal-inner {
            box-shadow: 0px 3px 17px rgb(0 0 0 / 13%);
            padding: 32px;
            width: 300px;
        }
        
        .confirmation-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .btn {
            transition: all 0s, background-color .3s ease-in-out;
        }
    }
}
