.cursor-pointer {cursor: pointer !important;}

.full-height {height: 100% !important;}
.full-width {width: 100% !important;}
.hide-fully {display: none !important;}
.hide-visibility {visibility: hidden !important;}

.whitespace-nowrap {white-space: nowrap !important;}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        .#{$prefix}cursor-pointer {cursor: pointer !important;}
        .#{$prefix}full-height {height: 100% !important;}
        .#{$prefix}full-width {width: 100% !important;}
        .#{$prefix}hide-fully {display: none !important;}
        .#{$prefix}hide-visibility {visibility: hidden !important;}
        .#{$prefix}whitespace-nowrap {white-space: nowrap !important;}
    }
}
