// Types
.pos-relative {position: relative !important;}
.pos-absolute {position: absolute !important;}
.pos-fixed {position: fixed !important;}

// Locations
.pos-top {top: 0 !important;}
.pos-right {right: 0 !important;}
.pos-bottom {bottom: 0 !important;}
.pos-left {left: 0 !important;}
.pos-v-center {top: 50% !important; transform: translateY(-50%) !important;}
.pos-h-center {left: 50% !important; transform: translateX(-50%) !important;}
.pos-center {top: 50% !important; left: 50% !important; transform: translate(-50%,-50%) !important;}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        .#{$prefix}pos-relative {position: relative !important;}
        .#{$prefix}xs-pos-absolute {position: absolute !important;}
        .#{$prefix}xs-pos-fixed {position: fixed !important;}
        .#{$prefix}xs-pos-top {top: 0 !important;}
        .#{$prefix}xs-pos-right {right: 0 !important;}
        .#{$prefix}xs-pos-bottom {bottom: 0 !important;}
        .#{$prefix}xs-pos-left {left: 0 !important;}
        .#{$prefix}xs-pos-v-center {top: 50% !important; transform: translateY(-50%) !important;}
        .#{$prefix}xs-pos-h-center {left: 50% !important; transform: translateX(-50%) !important;}
        .#{$prefix}xs-pos-center {top: 50% !important; left: 50% !important; transform: translate(-50%,-50%) !important;}
    }
}