.default-layout {
  background: #F9F8F6;

  .top-bar {
    background: #172644;
    padding: 22px 48px;
    display: flex;

    h3 {
      color: #fff;
      flex: 1 0 auto;
      margin: 0;
      top: 4px;
    }

    .topbar-right {
      color: #fff;
      flex: 0 0 auto;
      margin: 0;

      a, a:hover, a:visited {
        color: #fff;
      }
    }
  }
  .login-card {
      background: #FFFFFF;
      box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.13);
      border-radius: 16px;
      padding: 56px;
  }
}

.dashboard {
    display: flex;
    height: 100vh;

    #sidebar {
      position: relative;
      flex: 0 0 auto;
      color: #fff;
      max-width: 340px;
      background: #172644;
      overflow-y: scroll;

      .sidebar {
        padding: 17px 24px;
        transition: all 0.3s ease;
      }

    .logo {
      color: #fff;
      padding-top: 10px;
    }

    .class-link {
      display: block;
      padding: 8px;
      border-radius: 8px;
      color: #fff;
      text-decoration: none;

      .class-icon {
        display: inline-block;
        vertical-align: middle;
        background: #EEF4FE;
        color: #1A50B6;
        border-radius: 8px;
        margin-right: 16px;
        font-family: 'Adelle';
        font-weight: 700;
        font-size: 24px;
        vertical-align: middle;
        padding-top: 17px;
        padding-bottom: 17px;
        width: 82px;
        text-align: center;

        span {
          position: relative;
          top: 4px;
        }
      }
      .class-name {
        display: inline-block;
        vertical-align: middle;
        font-family: 'Mulish';
        font-weight: 800;
        font-size: 16px;
        width: calc(100% - 98px);
      }
      &:nth-of-type(4n+1) .class-icon {
        background: #EEF4FE;
        color: #1A50B6;
      }
      &:nth-of-type(4n+2) .class-icon {
          background: #FDF2E5;
          color: #A75C0A;
        }
        &:nth-of-type(4n+3) .class-icon {
          background: #EDFBE7;
          color: #006104;
        }
        &:nth-of-type(4n+4) .class-icon {
          background: #FBEAE7;
          color: #B92D0C;
        }
        &.active {
          border: 2px solid #FFFFFF;
          border-radius: 8px;
        }
    }
    .toggle-btn {
      position: absolute;
      top: 20px;
      right: 24px;
      width: 32px;
      height: 32px;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z' fill='%23FDF2E5'/%3E%3Cpath d='M15.8293 22.6587L9 15.8294L15.8293 9.00016' stroke='%23B92D0C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M22.8293 22.6587L16 15.8294L22.8293 9.00016' stroke='%23B92D0C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      cursor: pointer;
    }
    .status-tabs {
      margin-top: 50px;
      position: relative;

      &::after {
        content: "";
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: -24px;
        right: -24px;
      }

      a {
        cursor: pointer;
        display: inline-block;
        margin-right: 56px;
        font-weight: bold;
        &.active {
          border-bottom: 3px solid #fff;
        }
      }
    }
    .sidebar.collapsed {
      padding: 17px 16px;
      width: 88px;

      * {
        visibility: hidden;
      }
      .logo, .status-tabs, .class-search {
        display: none;
      }
      .toggle-btn {
        visibility: visible;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
      .class-list {
        margin-top: 80px;
      }
      .class-link {
        padding: 0;
        margin-bottom: 40px;
        .class-icon {
          visibility: visible;
          width: 100%;
          margin-right: 0;
          span {
            visibility: visible;
          }
        }
        .class-name {
          display: none;
        }
        &.active {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: calc(100% + 10px);
            width: calc(100% + 10px);
            visibility: visible;
            border: 2px solid #FFFFFF;
            border-radius: 8px;
          }
        }
      }
    }
  }

  .content {
    flex: 1 1 auto;
    overflow: auto;
    background: #F9F8F6;
    padding: 22px 48px;
  }
}

