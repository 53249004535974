.d-none { display: none !important; }
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-table { display: table !important; }
.d-table-row { display: table-row !important; }
.d-table-cell { display: table-cell !important; }

// Flex
.d-flex { display: flex !important; }
.d-inline-flex { display: inline-flex !important; }

.justify-start {justify-content: flex-start !important;}
.justify-end {justify-content: flex-end !important;}
.justify-center {justify-content: center !important;}
.justify-around {justify-content: space-around !important;}
.justify-between {justify-content: space-between !important;}

.align-start {align-items: flex-start !important;}
.align-end {align-items: flex-end !important;}
.align-center {align-items: center !important;}
.flex-col {flex-direction: column !important;}
.flex-row {flex-direction: row !important;}
.flex-grow {flex-grow: 1 !important;}
.flex-shrink {flex-shrink: 1 !important;}
.flex-wrap {flex-wrap: wrap !important;}
.flex-no-wrap {flex-wrap: nowrap !important;}
.row-reverse {flex-direction: row-reverse !important;}
.col-reverse {flex-direction: column-reverse !important;}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        .#{$prefix}d-none { display: none !important; }
        .#{$prefix}d-inline { display: inline !important; }
        .#{$prefix}d-inline-block { display: inline-block !important; }
        .#{$prefix}d-block { display: block !important; }
        .#{$prefix}d-table { display: table !important; }
        .#{$prefix}d-table-row { display: table-row !important; }
        .#{$prefix}d-table-cell { display: table-cell !important; }
        .#{$prefix}d-flex { display: flex !important; }
        .#{$prefix}d-inline-flex { display: inline-flex !important; }
        .#{$prefix}justify-start {justify-content: flex-start !important;}
        .#{$prefix}justify-end {justify-content: flex-end !important;}
        .#{$prefix}justify-center {justify-content: center !important;}
        .#{$prefix}justify-around {justify-content: space-around !important;}
        .#{$prefix}justify-between {justify-content: space-between !important;}
        .#{$prefix}align-start {align-items: flex-start !important;}
        .#{$prefix}align-end {align-items: flex-end !important;}
        .#{$prefix}align-center {align-items: center !important;}
        .#{$prefix}flex-col {flex-direction: column !important;}
        .#{$prefix}flex-row {flex-direction: row !important;}
        .#{$prefix}flex-grow {flex-grow: 1 !important;}
        .#{$prefix}flex-shrink {flex-shrink: 1 !important;}
        .#{$prefix}flex-wrap {flex-wrap: wrap !important;}
        .#{$prefix}flex-no-wrap {flex-wrap: nowrap !important;}
        .#{$prefix}row-reverse {flex-direction: row-reverse !important;}
        .#{$prefix}col-reverse {flex-direction: column-reverse !important;}
    }
}