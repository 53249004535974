@each $typeLabel, $typeValue in (p: padding, m: margin) {
    @each $sizeLabel, $sizeValue in (n: 0, xxxs: $spacing_xxxs, xxs: $spacing_xxs, xs: $spacing_xs, s: $spacing_s, m: $spacing_m, l: $spacing_l, xl: $spacing_xl, xxl: $spacing_xxl, xxxl: $spacing_xxxl) {
        @each $locationLabel, $locationValue in (t: top, r: right, b: bottom, l: left) {
            .#{$typeLabel}#{$locationLabel}#{$sizeLabel} {
                #{$typeValue}-#{$locationValue}: #{$sizeValue}px !important;
            }
        }
        @each $locationLabel in (h, v) {
            .#{$typeLabel}#{$locationLabel}#{$sizeLabel} {
                @if ($locationLabel == h) {
                    #{$typeValue}-left: #{$sizeValue}px !important;
                    #{$typeValue}-right: #{$sizeValue}px !important;
                }
                @else {
                    #{$typeValue}-top: #{$sizeValue}px !important;
                    #{$typeValue}-bottom: #{$sizeValue}px !important;
                }
            }
        }
        .#{$typeLabel}a#{$sizeLabel} {
            #{$typeValue}: #{$sizeValue}px !important;
        }
    }
}

.m-auto {margin: auto !important;}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        @each $typeLabel, $typeValue in (p: padding, m: margin) {
            @each $sizeLabel, $sizeValue in (n: 0, xxxs: $spacing_xxxs, xxs: $spacing_xxs, xs: $spacing_xs, s: $spacing_s, m: $spacing_m, l: $spacing_l, xl: $spacing_xl, xxl: $spacing_xxl, xxxl: $spacing_xxxl) {
                @each $locationLabel, $locationValue in (t: top, r: right, b: bottom, l: left) {
                    .#{$prefix}#{$typeLabel}#{$locationLabel}#{$sizeLabel} {
                        #{$typeValue}-#{$locationValue}: #{$sizeValue}px !important;
                    }
                }
                @each $locationLabel in (h, v) {
                    .#{$prefix}#{$typeLabel}#{$locationLabel}#{$sizeLabel} {
                        @if ($locationLabel == h) {
                            #{$typeValue}-left: #{$sizeValue}px !important;
                            #{$typeValue}-right: #{$sizeValue}px !important;
                        }
                        @else {
                            #{$typeValue}-top: #{$sizeValue}px !important;
                            #{$typeValue}-bottom: #{$sizeValue}px !important;
                        }
                    }
                }
                .#{$prefix}#{$typeLabel}a#{$sizeLabel} {
                    #{$typeValue}: #{$sizeValue}px !important;
                }
            }
        }

        .#{$prefix}m-auto {margin: auto !important;}
    }
}