.masonry-wrap {
    .masonry {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-left: -25px;
        width: auto;
        .masonry-column {
            padding-left: 25px; /* gutter size */
            background-clip: padding-box;

            .card.submission {
                margin-bottom: 23px;
            }
        }
    }
}

.submissions-grid {
    .submissions-control-area {
        display: flex; 
        flex-direction: column; 
        align-items: end;
    }
    .submissions-header {
        display: flex;
        align-items: center;
        margin-bottom: 19px;
        & > h4 {
            margin-right: 8px;
        }
        .submissions-count {
            color: #FFFFFF;
            background: #172644;
            width: 40px;
            height: 40px;
            border-radius: 40px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            h4 {
                color: #fff;
            }
        }
    }
    @media screen and (min-width: 768px) {
        .submissions-table {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            .submissions-table-item {
                position: relative;
                box-sizing: border-box;
            }
        }
    }
}
