// Transform
.txt-lowercase  { text-transform: lowercase !important; }
.txt-uppercase  { text-transform: uppercase !important; }
.txt-capitalize { text-transform: capitalize !important; }
.txt-sentence { text-transform: sentence !important; }

// Weight and italics
.font-black         { font-weight: 900 !important }
.font-bolder        { font-weight: 800 !important }
.font-bold          { font-weight: 700 !important }
.font-normal        { font-weight: 400 !important }
.font-light         { font-weight: 300 !important }
.font-lighter       { font-weight: 100 !important }
.font-italic        { font-style: italic !important; }
.font-monospace     { font-family: monospace !important;}

// Text Decoration
.ntd {text-decoration: none !important;}
.txt-underline {text-decoration: underline !important;}

// Alignment
.txt-c {text-align: center !important}
.txt-r {text-align: right !important}
.txt-l {text-align: left !important}

@media screen and (max-width: 767px) {
    .xs-txt-lowercase { text-transform: lowercase !important; }
    .xs-txt-uppercase { text-transform: uppercase !important; }
    .xs-txt-capitalize { text-transform: capitalize !important; }
    .xs-txt-sentence { text-transform: sentence !important; }
    .xs-font-italic { font-style: italic !important; }
    .xs-font-monospace { font-family: monospace !important;}
    .xs-ntd {text-decoration: none !important;}
    .xs-txt-underline {text-decoration: underline !important;}
    .xs-txt-c {text-align: center !important}
    .xs-txt-r {text-align: right !important}
    .xs-txt-l {text-align: left !important}
}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        .#{$prefix}txt-lowercase { text-transform: lowercase !important; }
        .#{$prefix}txt-uppercase { text-transform: uppercase !important; }
        .#{$prefix}txt-capitalize { text-transform: capitalize !important; }
        .#{$prefix}txt-sentence { text-transform: sentence !important; }
        .#{$prefix}font-italic { font-style: italic !important; }
        .#{$prefix}font-monospace { font-family: monospace !important;}
        .#{$prefix}ntd {text-decoration: none !important;}
        .#{$prefix}txt-underline {text-decoration: underline !important;}
        .#{$prefix}txt-c {text-align: center !important}
        .#{$prefix}txt-r {text-align: right !important}
        .#{$prefix}txt-l {text-align: left !important}
    }
}
