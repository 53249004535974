
html {
  font-size: 62.5%;
}

// /* text size */
//  .type-xxxl {
//   font-size: #{$type_xxxl}rem;
// }
// .type-xxl {
//   font-size: #{$type_xxl}rem;
// }
// .type-xl {
//   font-size: #{$type_xl}rem;
// }
// .type-l {
//   font-size: #{$type_l}rem;
// }
// .type-m {
//   font-size: #{$type_m}rem;
// }
// .type-s {
//   font-size: #{$type_s}rem;
// }
// .type-xs {
//   font-size: #{$type_xs}rem;
// }
// .type-xxs {
//   font-size: #{$type_xxs}rem;
// }
// @media screen and (max-width: 767px) {
//   .type-xxxl {
//     font-size: #{$type_xxxl_mobile}rem;
//   }
//   .type-xxl {
//     font-size: #{$type_xxl_mobile}rem;
//   }
//   .type-xl {
//     font-size: #{$type_xl_mobile}rem;
//   }
//   .type-l {
//     font-size: #{$type_l_mobile}rem;
//   }
//   .type-m {
//     font-size: #{$type_m_mobile}rem;
//   }
//   .type-s {
//     font-size: #{$type_s_mobile}rem;
//   }
//   .type-xs {
//     font-size: #{$type_xs_mobile}rem;
//   }
//   .type-xxs {
//     font-size: #{$type_xxs_mobile}rem;
//   }
// }

// /* line height */
// .line-xxxl {
//   line-height: #{$line_xxxl}rem;
// }
// .line-xxl {
//   line-height: #{$line_xxl}rem;
// }
// .line-xl {
//   line-height: #{$line_xl}rem;
// }
// .line-l {
//   line-height: #{$line_l}rem;
// }
// .line-m {
//   line-height: #{$line_m}rem;
// }
// .line-s {
//   line-height: #{$line_s}rem;
// }
// .line-xs {
//   line-height: #{$line_xs}rem;
// }
// .line-xxs {
//   line-height: #{$line_xxs}rem;
// }
// @media screen and (max-width: 767px) {
//   .line-xxxl {
//     line-height: #{$line_xxxl_mobile}rem;
//   }
//   .line-xxl {
//     line-height: #{$line_xxl_mobile}rem;
//   }
//   .line-xl {
//     line-height: #{$line_xl_mobile}rem;
//   }
//   .line-l {
//     line-height: #{$line_l_mobile}rem;
//   }
//   .line-m {
//     line-height: #{$line_m_mobile}rem;
//   }
//   .line-s {
//     line-height: #{$line_s_mobile}rem;
//   }
//   .line-xs {
//     line-height: #{$line_xs_mobile}rem;
//   }
//   .line-xxs {
//     line-height: #{$line_xxs_mobile}rem;
//   }
// }

// /* font weight */
// .font-lighter {
//   font-weight: #{$font_lighter};
// }
// .font-light {
//   font-weight: #{$font_light};
// }
// .font-normal {
//   font-weight: #{$font_normal};
// }
// .font-bold {
//   font-weight: #{$font_bold};
// }
// .font-bolder {
//   font-weight: #{$font_bolder};
// }
// .font-black {
//   font-weight: #{$font_black};
// }

// body {
//   @extend .type-xs;
//   @extend .line-s;
// }

// html, body {
//   font-family: 'Open Sans', sans-serif;
//   color: color('neutral-darker');
// }

// h1 {
//   @extend .type-xxl;
//   @extend .line-xxl;
// }
// h2 {
//   @extend .type-xl;
//   @extend .line-xl;
// }
// h3 {
//   @extend .type-l;
//   @extend .line-l;
// }
// h4 {
//   @extend .type-m;
//   @extend .line-m;
// }
// h5 {
//   @extend .type-s;
//   @extend .line-s;
// }
// h6 {
//   @extend .type-xs;
//   @extend .line-xs;
// }
// small {
//   @extend .type-xs;
//   @extend .line-xs;
// }