@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;800&display=swap');
@font-face {
  font-family: 'Adelle';
  src: url("../assets/Adelle_Bold.otf") format("opentype");
}

@font-face {
  font-family: 'wim-icons';
  src:  url('../assets/wim-icons.eot?20v8a');
  src:  url('../assets/wim-icons.eot?20v8a#iefix') format('embedded-opentype'),
    url('../assets/wim-icons.ttf?20v8a') format('truetype'),
    url('../assets/wim-icons.woff?20v8a') format('woff'),
    url('../assets/wim-icons.svg?20v8a#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


// Abstract
@import 'abstract/variables';
@import 'abstract/functions';
@import 'abstract/mixins';
@import 'abstract/grid-variables';
@import "abstract/custom";

// Mixins
@import "mixins/text-emphasis";
@import "mixins/breakpoints";
@import "mixins/clearfix";
@import "mixins/breakpoints";
@import "mixins/hover";
@import "mixins/image";
@import "mixins/visibility";
@import "mixins/text-hide";
@import "mixins/text-truncate";
@import "mixins/grid-framework";
@import "mixins/grid";

// Base
@import 'base/normalize';
@import 'base/typography';

// Layout
@import "layout/grid";

// Utilities
// @import 'utilities/borders';
@import 'utilities/colors';
@import 'utilities/display';
@import 'utilities/positioning';
@import 'utilities/spacing';
@import 'utilities/typography';
@import "utilities/clearfix";
@import "utilities/visibility";
@import "utilities/misc";

// Components
@import "components/breadcrumbs";
@import "components/inputs";
@import "components/buttons";
@import "components/cards";
@import "components/tables";
@import "components/sidebar";
@import "components/modal";
@import "components/submissions-grid";
@import "components/comment";
@import "components/slideshow";

// Icons
@import "utilities/icons";

