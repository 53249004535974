.card {
  background: color('neutral-brighter');
  box-shadow: 0px 3px 17px rgb(0 0 0 / 13%);
  border-radius: 8px;
  padding: 20px 24px;
  margin-bottom: 30px;

  &.challenge {
    &.closed {
      color: color('dark-gray');
    }
  }

  .tag {
    height: 22px;
    border-radius: 4px;
    display: inline-block;
    padding: 0 8px;
    color: color('neutral-brighter');
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 8px;

    &.today {
      background: color('medium-orange');
    }
    &.tomorrow {
      background: color('light-orange');
      color: color('dark-orange');
    }
    &.closed {
      background: color('medium-blue');
    }
  }

  &.submission {
    width:100%;
    overflow: hidden;
    margin-bottom: 0px;
    .image-holder {
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -20px;
      //height: 0;
      //padding-bottom: 100%;
      background-size: cover;
    }
    .card-controls {
      display: flex;
      padding-top: 24px;
    }
  }

  &.challenge-group {
    height: 190px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .submissions {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 8px;
      color: color('dark-orange');
    }

    .arrow {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.active-challenge {
      border-left: 12px solid color('approved-green');

      .submissions {
        background: color('light-orange');
      }
      .arrow {
        background: color('light-orange');
      }
    }
    &.expired-challenge {
      border-left: 12px solid color('medium-blue');

      .submissions {
        background: color('light-orange');
      }
      .arrow {
        background: color('light-orange');
      }
    }
    &.student {
      border-left: 12px solid color('medium-orange');

      .submissions {
        background: color('light-orange');
      }
      .arrow {
        background: color('light-orange');
      }
    }
    &.teaching-assistant {
      border-left: 12px solid color('medium-yellow');

      .submissions {
        background: color('light-yellow');
      }
      .arrow {
        background: color('light-yellow');
      }
    }

  }

  &.challenge-details {
    .notification {
      display: flex;
      align-items: center;

      .icon-holder {
        margin-left: 8px;
        height: 24px;
        width: 24px;
        color: color('dark-orange');
        font-size: 14px;
      }
    }

    .responses {
      .response-type {
        display: flex;
        align-items: center;
        margin: 0 24px;

        .icon-holder {
          margin-right: 12px;
        }
      }
    }

    hr {
      border: 0;
      background: #172644;
      height: 2px;
    }
  }
}

.challenge-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .actions {
    font-weight: bold;

    a {
      margin-left: 24px;

      .btn {
        width: 32px;
        height: 32px;
        background: #B92D0C;
        border-radius: 4px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 18px;
        line-height: 18px;
        margin-right: 8px;
        padding: 0;

        &:hover {
          font-weight: normal;
          background: #831B03;
        }
      }

      .icon-caret-down {
        margin-left: 8px;
      }
    }
  }
}

.control-holder {
  margin-right: 24px;
  display: flex;
  align-items: center;

  .card-control-count {
    margin-left: 8px;
    font-weight: 800;
    font-size: 16px;
    line-height: 140%;

    &[data-count="0"] {
      display: none;
    }
  }

  .card-control-btn {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: none;
    appearance: none;
    font-size: 22px;

    .icon {
      height: 24px;
      width: 24px;
    }
  }
}