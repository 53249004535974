
//********************
// COLORS
//********************

$colors: (
  'neutral-darker': #000000,
'neutral': #eaeaea,
'neutral-brighter': #ffffff,
'heavy-orange': #831B03,
'dark-orange': #B92D0C,
'medium-orange': #DA3B15,
'light-orange': #FBEAE7,
'disabled-orange': #DC9685,
'dark-blue': #172644,
'medium-blue': #1A50B6,
'light-blue': #EEF4FE,
'gray-blue': #9EA7BA,
'dark-yellow': #A75C0A,
'medium-yellow': #F29D41,
'light-yellow': #FDF2E5,
'dark-gray': #69696B,
'medium-gray': #B3B3B3,
'light-gray': #EFEFF1,
'dark-green': #006104,
'light-green': #EDFBE7,
'approved-green': #0D9501,
'error-red': #D61A1A,
'beige': #F9F8F6
);
$colorsArr: 'neutral-darker','neutral','neutral-brighter','heavy-orange','dark-orange','medium-orange','light-orange','disabled-orange','dark-blue','medium-blue','light-blue','gray-blue','dark-yellow','medium-yellow','light-yellow','dark-gray','medium-gray','light-gray','dark-green','light-green','approved-green','error-red','beige';

//********************
// TYPOGRAPHY
//********************

// Sizes

$type_base: 1.6;
$type_xxxl: 6.4;
$type_xxl: 4.8;
$type_xl: 3.6;
$type_l: 2.4;
$type_m: 1.8;
$type_s: 1.6;
$type_xs: 1.4;
$type_xxs: 1.2;
$type_xxxs: 1;
  
$type_base_mobile: 1.6;
$type_xxxl_mobile: 25.6;
$type_xxl_mobile: 12.8;
$type_xl_mobile: 6.4;
$type_l_mobile: 4;
$type_m_mobile: 3.2;
$type_s_mobile: 2.4;
$type_xs_mobile: 1.6;
$type_xxs_mobile: 0.8;
$type_xxxs_mobile: 0.4;
  
// Line Height
$line_base: 2.4;
$line_xxxl: 6.4;
$line_xxl: 4;
$line_xl: 3.2;
$line_l: 3;
$line_m: 2.7;
$line_s: 2.4;
$line_xs: 2.1;
$line_xxs: 1.8;
$line_xxxs: 1.5;
  
$line_base_mobile: 2.4;
$line_xxxl_mobile: 6.4;
$line_xxl_mobile: 4;
$line_xl_mobile: 3.2;
$line_l_mobile: 3;
$line_m_mobile: 2.7;
$line_s_mobile: 2.4;
$line_xs_mobile: 2.1;
$line_xxs_mobile: 1.8;
$line_xxxs_mobile: 1.5;
  
// Font Weight
$font_lighter: 100;
$font_light: 300;
$font_normal: 400;
$font_bold: 700;
$font_bolder: 800;
$font_black: 900;
  
  
//********************
// SPACING
//********************

$spacing_base: 16;
$spacing_xxxl: 128;
$spacing_xxl: 96;
$spacing_xl: 64;
$spacing_l: 40;
$spacing_m: 32;
$spacing_s: 24;
$spacing_xs: 16;
$spacing_xxs: 8;
$spacing_xxxs: 4;
  
$spacing_base_mobile: 16;
$spacing_xxxl_mobile: 128;
$spacing_xxl_mobile: 96;
$spacing_xl_mobile: 64;
$spacing_l_mobile: 40;
$spacing_m_mobile: 32;
$spacing_s_mobile: 24;
$spacing_xs_mobile: 16;
$spacing_xxs_mobile: 8;
$spacing_xxxs_mobile: 4;


//********************
// ELEMENTS
//********************
$elementsArr: 'body','h1','h2','h3','h4','h5','small','.type-l';


body {
  	font-family: 'Mulish', sans-serif;;
	font-size: 1.6rem;
	color: map-get($colors, "neutral-darker");
	line-height: 2.2rem;
	font-weight: #{$font_normal};

  	@media screen and (max-width: 767px) {
    		font-size: 1.6rem;
		line-height: 2.2rem;
  	}
}

h1 {
  	font-weight: #{$font_bold};
	line-height: 4rem;
	font-size: 4.8rem;
	font-family: 'Adelle', serif;;

  	@media screen and (max-width: 767px) {
    		line-height: 4rem;
		font-size: 4.8rem;
  	}
}

h2 {
  	font-size: 3.2rem;
	color: map-get($colors, "neutral-darker");
	line-height: 3.8rem;
	font-weight: #{$font_bold};
	font-family: 'Adelle', serif;;

  	@media screen and (max-width: 767px) {
    		font-size: 3.2rem;
		line-height: 3.8rem;
  	}
}

h3 {
  	line-height: 2.8rem;
	color: map-get($colors, "neutral-darker");
	font-family: 'Adelle', serif;;
	font-weight: #{$font_bold};
	font-size: 2.4rem;

  	@media screen and (max-width: 767px) {
    		line-height: 2.8rem;
		font-size: 2.4rem;
  	}
}

h4 {
  	font-size: 2rem;
	line-height: 2.4rem;
	color: map-get($colors, "neutral-darker");
	font-family: 'Adelle', serif;;
	font-weight: #{$font_bold};

  	@media screen and (max-width: 767px) {
    		font-size: 2rem;
		line-height: 2.4rem;
  	}
}

h5 {
  	color: map-get($colors, "neutral-darker");
	font-family: 'Adelle', serif;;
	line-height: 1.9rem;
	font-size: 1.6rem;
	font-weight: #{$font_bold};

  	@media screen and (max-width: 767px) {
    		line-height: 1.9rem;
		font-size: 1.6rem;
  	}
}

small {
  	font-family: 'Mulish', sans-serif;;
	font-size: 1.2rem;
	line-height: 1.7rem;
	color: map-get($colors, "neutral-darker");
	font-weight: #{$font_normal};

  	@media screen and (max-width: 767px) {
    		font-size: 1.2rem;
		line-height: 1.7rem;
  	}
}

.type-l {
  	font-family: 'Mulish', sans-serif;;
	font-weight: #{$font_bolder};
	color: map-get($colors, "neutral-darker");
	line-height: 2.5rem;
	font-size: 1.8rem;

  	@media screen and (max-width: 767px) {
    		line-height: 2.5rem;
		font-size: 1.8rem;
  	}
}





  
//********************
// BUTTONS
//********************

$buttons: (
  
  default: ( 
    base: (      
      color:          #ffffff,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0,
      border-color:   transparent,
      background:     #B92D0C,
      transition: all 0.3s ease-in-out,
      
    ),
    hover: (
      color:          #ffffff,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0,
      border-color:   transparent,
      background:     #831B03,
      
    ),
    disable: (
      color:          #ffffff,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0,
      border-color:   transparent,
      background:     #DC9685,
      
    )
  ),
  secondary: ( 
    base: (      
      color:          #B92D0C,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0.2rem,
      border-color:   #B92D0C,
      background:     #ffffff,
      transition: all 0.3s ease-in-out,
      
    ),
    hover: (
      color:          #831B03,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0.2rem,
      border-color:   #831B03,
      background:     #ffffff,
      
    ),
    disable: (
      color:          #DC9685,
      font-weight:    800,
      padding-left:   2.4rem,
      padding-right:  2.4rem,
      height:         4.8rem,
      border-radius:  8px,
      border-width:   0.2rem,
      border-color:   #DC9685,
      background:     #ffffff,
      
    )
  )
);



  
  //********************
  // LINKS
  //********************
  
  $links: (
    
    default: ( 
      base: (
        color:                  inherit,
        font-weight:            700,
        background:             inherit,
        text-decoration-color:  inherit,
        text-decoration-line:   underline,
        text-decoration-style:  solid,
        transition: all 0.3s ease-in-out,
        
      ),
      hover: (
        color:                  inherit,
        font-weight:            800,
        background:             inherit,
        text-decoration-color:  inherit,
        text-decoration-line:   underline,
        text-decoration-style:  solid,
        
      ),
      disable: (
        color:                  inherit,
        font-weight:            700,
        background:             inherit,
        text-decoration-color:  inherit,
        text-decoration-line:   underline,
        text-decoration-style:  solid,
        
      )
    ),
  );

  
