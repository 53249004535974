
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'wim-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon.icon-dark {
    color: #000 !important;
  }
  

  .icon-favorite-filled:before {
    content: "\e91e";
  }
  .icon-display-grid:before {
    content: "\e900";
  }
  .icon-arrow-right:before {
    content: "\e901";
  }
  .icon-arrow-up:before {
    content: "\e902";
  }
  .icon-back:before {
    content: "\e903";
  }
  .icon-caret-down:before {
    content: "\e904";
  }
  .icon-caret-up:before {
    content: "\e905";
  }
  .icon-check:before {
    content: "\e906";
  }
  .icon-collapse-left:before {
    content: "\e907";
  }
  .icon-collapse-right:before {
    content: "\e908";
  }
  .icon-comment:before {
    content: "\e909";
  }
  .icon-display-list:before {
    content: "\e90a";
  }
  .icon-display-stack:before {
    content: "\e90b";
  }
  .icon-download-csv:before {
    content: "\e90c";
  }
  .icon-download:before {
    content: "\e90d";
  }
  .icon-edit:before {
    content: "\e90e";
  }
  .icon-eye-closed:before {
    content: "\e90f";
  }
  .icon-eye-open:before {
    content: "\e910";
  }
  .icon-favorite:before {
    content: "\e911";
  }
  .icon-forward:before {
    content: "\e912";
  }
  .icon-info:before {
    content: "\e913";
  }
  .icon-like:before {
    content: "\e914";
  }
  .icon-link:before {
    content: "\e915";
  }
  .icon-M:before {
    content: "\e916";
  }
  .icon-mail:before {
    content: "\e917";
  }
  .icon-paper-plane:before {
    content: "\e918";
  }
  .icon-plus:before {
    content: "\e919";
  }
  .icon-search:before {
    content: "\e91a";
  }
  .icon-trash:before {
    content: "\e91b";
  }
  .icon-W:before {
    content: "\e91c";
  }
  .icon-x:before {
    content: "\e91d";
  }
  