.slideshow-wrap {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  overflow-y: scroll;

  .slideshow-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -58px;
    margin-bottom: 40px;

    .slideshow-close {
      margin-left: 26px;
      background: #B92D0C;
      color: white;
      font-size: 28px;
      cursor: pointer !important;
      pointer-events: initial !important;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #831B03;
      }
    }
  }
  .slideshow-main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .slides-wrap {
      position: relative; 
      flex-grow: 1;
      margin-bottom: 32px;
      min-height: 300px;

      .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        overflow: hidden;
        opacity: 0;
        transition: opacity .5s ease-in-out .5s;

        &.active {
          opacity: 1;
          transition: opacity .5s ease-in-out;
        }
      }
    }
    .slide-desc-wrap {
      display: flex;
      margin-bottom: 24px;
      overflow: hidden;
  
      .slide-desc {
        min-width: 100%;
        opacity: 0;
        transition: opacity .5s ease-in-out .5s, margin-left 0s;
      
        &.active {
          opacity: 1;
          transition: opacity .5s ease-in-out, margin-left 0s .5s;
        }
      }
    }
    .slide-controls {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      width: calc(100% + 32px);
      margin-left: -16px;

      .slide-control {
        cursor: pointer;
      }

      .icon-holder {
        height: 32px;
        width: 32px;
        background: #000000;
        border-radius: 50%;
        color: white;
        font-size: 18px;
      }

      .prev .icon-holder { padding-right: 4px; }
      .next .icon-holder { padding-left: 4px; }
    }
  }
  .slideshow-thumbnails {
    .thumbnails-header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;
      
      .thumbnails-toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        
        .icon-holder {
          margin-right: 8px;
          color: white;
          background: #B92D0C;
          font-size: 24px;
          transition: all 0.3s ease-in-out;
          pointer-events: initial;
          
          &:hover {
            background: #831B03;
          }
        }
      }
    }

    .thumbnails {
      display: flex;
      overflow-x: scroll;
      padding-bottom: 16px;
      scroll-behavior: smooth;
      padding-right: calc(100% - 305px);
      visibility: hidden;
      height: 0;

      &.toggled {
        visibility: visible;
        height: auto;
      }

      .thumbnail {
        height: 160px;
        width: 265px;
        min-width: 265px;
        margin-right: 40px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .slide-label {
          background: #FDF2E5;
          border-radius: 4px;
          position: absolute;
          z-index: 1;
          top: 8px;
          right: 8px;
          padding: 8px 16px;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }
  }
}