.form-control {
  width: 415px;
  max-width: 100%;
  position: relative;
  margin-bottom: 16px;

  label {
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    font-size: 16px;
    line-height: 140%;
    display: block;
    margin-bottom: 8px;
  }

  .input-wrap {
    position: relative;

    .icon, .icon-holder {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      cursor: pointer;
    }

    .icon-holder .icon {
      position: inherit;
      top: unset;
      transform: unset;
      right: unset;
    }
  }

  input[type="text"],
  input[type="search"],
  input[type="password"],
  .dropdown,
  textarea:not(.tip) {
    background: #fff;
    border: 1px solid color('gray-blue');
    border-radius: 8px;
    box-sizing: border-box;
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    outline: 0;
    font-size: 1.6rem;
    font-family: 'Mulish', sans-serif; 
    margin-bottom: 8px;
    width: 100%;
    
    &::placeholder {
      color: color('gray-blue');
      font-style: italic;
    }

    &:focus {
      border: 1px solid #172644;
      outline: 1px solid #172644;
    }

    &[disabled] {
      background-color: color('light-gray');
      border-color: color('medium-gray');
      color: color('dark-gray');
      pointer-events: none;
      cursor: not-allowed;
      &::placeholder {
        color: color('dark-gray');
      }
    }

    &.comment {
      padding-right: 48px;
    }
    &.search {
      padding-left: 48px;

      & + .icon {
        right: unset;
        left: 12px;
      }
    }
  }
  
  textarea {
    height: 130px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .dropdown {
    padding: 0;

    .dropdown-label {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .icon-holder {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FDF2E5;
      border-radius: 4px;
      transform: translateY(-50%);
      pointer-events: none;
      .icon {
        transform: rotate(0deg);
        transition: all .3s ease-in-out;
      }
    }

    .dropdown-options {
      display: none;
      position: absolute;
      top: 100%;
      margin-top: 4px;
      width: 100%;
      left: 0;
      box-shadow: 0px 3px 17px rgb(0 0 0 / 13%);
      border-radius: 8px;
      padding: 16px 8px;
      z-index: 10;
      background: white;

      .option {
        min-height: 40px;
        margin-bottom: 8px;
        padding: 8px 16px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &.selected, &:hover {
          background: color('light-yellow');
        }
      }
    }

    &.toggled {
      border: 1px solid #172644;
      outline: 1px solid #172644;
      .icon-holder .icon {
        transform: rotate(180deg);
      }
      .dropdown-options {
        display: block;
      }
    }


    &.simple {
      border: 0;
      outline: 0;

      .dropdown-label {
        width: auto;
        display: inline-flex;
        position: relative;
        padding-left: 0;

        .icon {
          right: -8px;
        }
      }

      &.toggled .dropdown-options {
        width: auto;
        padding: 24px;

        .option {
          margin-bottom: 0;

          &.selected,
          &:hover {
            background: none;
          }
          &.selected {
            font-weight: bold;
          }
        }
      }
    }
  }

  .error-message {
    display: none;
    color: color('error-red');
  }
  &.error {
    input[type="text"],
    input[type="search"],
    input[type="password"],
    .dropdown,
    textarea {
      border-color: color('error-red');
      outline-color: color('error-red');
    }
    .error-message {
      display: block;
    }
  }

  .checkbox-container,
  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-weight: normal;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    
    .checkmark {
      background-color: white;
      height: 18px;
      left: 0;
      position: absolute;
      top: 2px;
      width: 18px;
      border: 2px solid color('dark-orange');
      border-radius: 4px;
      transition: background-color .2s ease-in-out;
    }
    
    &:hover input ~ .checkmark {
      background-color: color('light-orange');
    }
    
    input:checked ~ .checkmark {
      background-color: color('dark-orange');
    }
    
    .checkmark:after {
      content: "";
      position: absolute;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }
    
    input:checked ~ .checkmark:after {
      opacity: 1;
    }
    
    .checkmark:after {
      border: solid color('neutral-brighter');
      border-width: 0 2px 2px 0;
      height: 8px;
      left: 4px;
      top: 1px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 4px;
    }

    &.disabled {
      color: color('medium-gray');
      pointer-events: none;

      .checkmark {
        border-color: color('dark-gray');
      }
      input:checked ~ .checkmark {
        background-color: color('dark-gray');
      }
    }
  }

  .radio-container {
    .checkmark {
      border-radius: 50%;
    }
  }
  

  .multi-select {
    min-height: 48px;
    background: #FFFFFF;
    border: 1px solid #9EA7BA;
    border-radius: 8px;
    display: flex;
    padding: 0px 20px;
    flex-wrap: wrap;

    .tag {
      height: 32px;
      background: #FDF2E5;
      border-radius: 8px;
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      padding: 8px;
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 16px;

      .icon-holder {
        background-color: color('dark-orange');
        width: 23px;
        height: 23px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        pointer-events: initial;
        cursor: pointer;

        i {
          color: white;
          font-size: 23px;
        }
      }
    }

    .editor {
      align-items: center;
      display: inline-flex;
      height: 32px;
      padding: 8px;
      white-space: nowrap;
      flex-grow: 1;
      outline: 0;
      min-width: 50px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

}

.code-display .form-control {
  width: 100%;
}


.input-wrap .icon.icon-search {
  height: 24px;
  width: 24px;
  font-size: 18px;
}

.input-wrap .icon.icon-paper-plane {
  font-size: 24px;
  height: 24px;
  width: 24px;
  transform: translateY( calc(-50% + -3px));
}

.input-wrap .icon.icon-eye-open, .input-wrap .icon.icon-eye-closed {
  font-size: 20px;
  height: 20px;
  width: 20px;
  transform: translateY(calc(-50% - 2px));
}

.input-wrap .icon.icon-eye-closed {
  right: 10px;
}

