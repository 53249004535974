.breadcrumb {
  margin: 0 0 30px 0;
  padding: 0;
  display: block;
  list-style: none;

  li {
    display: inline;

    &:after {
      content: " >";
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
