.btn {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    outline: 0;
    border: 0;
    cursor: pointer;
    border-style: solid;
    white-space: nowrap;
  @if global-variable-exists(buttons) {
    @each $label, $states in $buttons {
      @each $state, $styles in $states {
          @if $label == default {
              @if $state == base {
                @each $style, $val in $styles {
                    @if $style != icon {
                        #{$style}: #{$val};
                    }
                    @if $style == icon {
                      @include icon($val);
                    }
                }
              } @else if $state == hover {
                  &:hover {
                      @each $style, $val in $styles {
                          @if $style != icon {
                              #{$style}: #{$val};
                          }
                          @if $style == icon {
                              @include icon($val);
                          }
                      }
                  }
              } @else if $state == disable {
                  &[disabled] {
                      @each $style, $val in $styles {
                          @if $style != icon {
                              #{$style}: #{$val};
                          }
                          @if $style == icon {
                              @include icon($val);
                          }
                      }
                  }
              }
          } @else {
              @if $state == base {
                  &.btn-#{$label} {
                      @each $style, $val in $styles {
                          @if $style != icon {
                              #{$style}: #{$val};
                          }
                          @if $style == icon {
                              @include icon($val);
                          }
                      }
                  }
              } @else if $state == hover {
                  &.btn-#{$label}:hover {
                      @each $style, $val in $styles {
                          @if $style != icon {
                              #{$style}: #{$val};
                          }
                          @if $style == icon {
                              @include icon($val);
                          }
                      }
                  }
              } @else if $state == disable {
                  &.btn-#{$label}[disabled] {
                      @each $style, $val in $styles {
                          @if $style != icon {
                              #{$style}: #{$val};
                          }
                          @if $style == icon {
                              @include icon($val);
                          }
                      }
                  }
              }
          }
      }
    }
  }
  
  }


// Additional buttons 
// 'DISPLAY'
.display-switch {
    display: flex;
    align-items: center;
    .display-switch__text {
        font-weight: 800;
        font-size: 16px;
        line-height: 22.4px;
        color: #000;
        margin-right: 16px;
    }
    .display-switch__switch {
        position: relative;
        height: 40px;
        width: 40px;
        margin-right: 16px;
        i {
            background: transparent;
            border-radius: 4px;
            display: flex;
            height: 40px;
            width: 40px;
            color: #000;
            align-items: center;
            justify-content: center;
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            width:100%;
            height: 100%;
        }
        input:checked ~ i {
            background: #B92D0C;
            color: #fff;
        }
        input:hover ~ i {
            background: #831B03;
            color: #fff;
        }
    }
}

.arrow-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background: #172644;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    cursor: pointer;
    &:hover, &.off {
        background: #9EA7BA;
    }

    &.off {
        cursor: default;
    }
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    color: #fff;
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
    cursor: pointer;
    font-size: 18px;
    background: #B92D0C;
    &.light-orange {
        background: #FBEAE7;
        color: #B92D0C;
    }
    &:hover, &.active {
        background: #831B03;
        &.light-orange {
            color: #fff;
        }
    }
    .icon-x {
        font-size: 28px;
    }
}