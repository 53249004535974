@each $color in $colorsArr {
    @each $targetKey, $targetValue in (bg: background-color, color: color) {
        .#{$targetKey}-#{$color} {
            #{$targetValue}: color($color) !important;
        }
    }
}
.bg-transparent {
    background-color: transparent !important;
}

@each $prefix, $media in $prefix-max-widths {
    @media screen and #{$media} {
        @each $color in $colorsArr {
            @each $targetKey, $targetValue in (bg: background-color, color: color) {
                .#{$prefix}#{$targetKey}-#{$color} {
                    #{$targetValue}: color($color) !important;
                }
            }
        }
        .#{$prefix}bg-transparent {
            background-color: transparent !important;
        }
    }
}