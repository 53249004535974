.comments-holder {
  .form-control {
    width: 100%;
  }
}

.comment {
  .comment-icon {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-family: Adelle, serif;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    padding-top: 3px;
    margin-right: 16px;
  }
  .comment-details {
    flex-grow: 1;
  }
  .comment-control-btn {
    background: none;
    border: 0;
    font-size: 18px;
    cursor: pointer;
  }
  

  .reply-holder {
    margin-right: -30px;
  }
  .comment-like {
    max-height: 20px;
  }
}