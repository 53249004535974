.table {
  width: 100%;

  .table-header,
  .table-row {
    display: flex;
    padding-right: 24px;
    padding-left: 24px;
  }

  .table-header {
    font-weight: bold;
    margin-bottom: 12px;
  }
  .table-row {
    height: 77px;
    background: #FFFFFF;
    box-shadow: 0px 3px 17px rgb(0 0 0 / 13%);
    border-radius: 8px;
    margin-bottom: 16px;
    align-items: center;
  }

  .table-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 8px;
    white-space: nowrap;
  }

  .table-cell-wide {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 8px;
    white-space: nowrap;
    text-align: center;
    width: 100%;
  }

  .table-title {
    text-align: left;
    width: 100%;
  }

  &.student {
    .table-cell {
      &:nth-child(1) { width: 30%; }
      &:nth-child(2) { width: 20%; }
      &:nth-child(3) { width: 30%; }
      &:nth-child(4) { width: 15%; }
      &:nth-child(5) {
        width: 5%;
        text-align: right;
      }
    }
  }
  &.classes {
    .table-cell {
      &:nth-child(1) { width: 70%; }
      &:nth-child(2) { width: 15%; }
      &:nth-child(3) {
        width: 15%;
        text-align: right;
      }
    }
  }
  &.challenges {
    .table-cell {
      &:nth-child(1) { width: 36%; }
      &:nth-child(2) { width: 22%; }
      &:nth-child(3) { width: 22%; }
      &:nth-child(4) {
        width: 20%;
        text-align: right;
      }
    }
  }
  &.submissions {
    .table-cell {
      &:nth-child(1) { width: 60%; }
      &:nth-child(2) { width: 10%; }
      &:nth-child(3) { width: 10%; }
      &:nth-child(4) {
        width: 20%;
        text-align: right;
      }
    }
  }
  &.submission-links {
    .table-cell {
      &:nth-child(1) { width: 20%; }
      &:nth-child(2) { width: 20%; }
      &:nth-child(3) { width: 20%; }
      &:nth-child(4) { width: 20%; }
      &:nth-child(5) {
        width: 20%;
        text-align: right;
      }
    }
  }
  &.student-submissions {
    .table-cell {
      &:nth-child(1) { width: 30%; }
      &:nth-child(2) { width: 50%; }
      &:nth-child(3) {
        width: 20%;
        text-align: right;
      }
    }
  }
  &.student-classes {
    .table-cell {
      &:nth-child(1) { width: 40%; }
      &:nth-child(2) { width: 20%; }
      &:nth-child(3) { width: 20%; }
      &:nth-child(4) { width: 20%; }
    }
  }
  &.responses {
    .table-cell {
      &:nth-child(1) { width: 30%; }
      &:nth-child(2) { width: 30%; }
      &:nth-child(3) { width: 40%; }
    }
  }
  &.select {
    .select-row-wrap {
      display: flex;
      width: 100%;
      align-items: center;

      .form-control {
        width: 42px;
        margin-bottom: 16px;
        height: 20px;
      }

      .table-header,
      .table-row {
        flex-grow: 1;
      }
    }
  }
  &.submission {
    .table-cell {
      &:nth-child(1) { flex-grow: 1; }
      &:nth-child(2) { width: 110px; }
      &:nth-child(3) { width: 150px; }
      &:nth-child(4) { width: 200px; }
    }
  }

  .delete {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: #FBEAE7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}